import * as React from "react"

const ProjectCard = ({ title, description, bookingUrl, active = true }: { title: string, description: string, bookingUrl?: string, active: boolean }) => {
    return (
        <div className="card mt-3">
            <div className="card-body">
                <h5 className="card-title"><strong>{title}</strong></h5>
                <p className="card-text">{description}</p>
                { bookingUrl && active ? <a href={bookingUrl} className="btn btn-secondary">Rezerwuj</a> : <a href="#" className="btn btn-primary disabled">{active ? "Zapisy wkrótce" : "Zakończone"}</a>} 
            </div>
        </div>
    )
}

export default ProjectCard