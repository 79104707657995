// i18next-extract-mark-ns-start main_page
import * as React from "react"
import { graphql } from "gatsby"
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next'
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroImage from "../components/season-opening-2023/hero-image"
import MobileLogotypesSection from "../components/mobile-logotypes-section"
import IntroSection from "../components/about/intro-section"
import BulletsSection from "../components/about/bullets-section"
import SchoolsSection from "../components/about/schools-section"
import SchoolsInfoModal from "../components/about/schools-info-modal"
import PageSection from "../components/page-section"
import ProjectCard from "../components/project-card";

const IndexPage = () => {

  const context = React.useContext(I18nextContext);
  const { t } = useTranslation();

  return (
    <Layout>
      <Seo title={"Bezpłatne zajęcia i atrakcje na wodzie w Warszawie"} />
      <PageSection>
        <h1><strong>Bezpłatne</strong><br />zajęcia i atrakcje na wodzie w Warszawie</h1>
        <div className="py-3"></div>
        <h3>Rezerwuj miejsca na aktywności w ramach bezpłatnych wydarzeń i projektów lokalnych</h3>
        <div className="py-3"></div>
      </PageSection>
      <PageSection className='bg-white text-black'>
        <h4 className="mb-5">Aktualna lista projektów i wydarzeń na 2024 rok:</h4>
        <div className="row">
        <div>
            <ProjectCard active={true} title="Motołódkowe podchody po Dzielnicy Wisła" description="Trzygodzinny wyścig nawigacyjny po Wiśle łodziami motorowymi dla 5 zespołów 4-osobowych." bookingUrl="https://wodnawarszawa.pl/podchody-wislane-2024/" />
          </div>
          <div>
            <ProjectCard active={true} title="Spływy kajakowe z Wawra" description="Bezpłatne trzygodzinne spływy kajakowe po Wiśle na trasie Plaża Romantyczna - Śródmieście odbywają się pod opieką przewodnika oraz z asystą ratowników na łodzi motorowej. Projekt jest kontynuacją z poprzedniego roku." bookingUrl="https://kajakwstolicy.pl/bezplatne-splywy-z-wawra-2024/" />
          </div>
          <div>
            <ProjectCard active={false} title="Łączą nas wiosła" description="Bezpłatne zajęcia w ramach akcji Łączą nas wiosła 2024 to kontynuacja projektu z zeszłego roku. 2-godzinne szkolenia z pływania na łodziach wioślarskich, deskach SUP oraz kajakach, prowadzone przez profesjonalnych instruktorów." bookingUrl="https://wodnawarszawa.pl/lacza-nas-wiosla-2024/" />
          </div>
          <div className="col-sm-6">
            <ProjectCard active={false} title="Spływy wakacyjne" description="Spływy wakacyjne 2024 to kontynuacja projektu z zeszłego roku. W ramach niego proponujemy bezpłatne spływy po Wiśle na trasach Wawer - Śródmieście oraz Śródmieście - Zoo. Spływ jest samodzielny,a przed wypłynięciem udzielany jest instruktaż." bookingUrl="https://kajakwstolicy.pl/splywy-wakacyjne/" />
          </div>
        </div>
        <h4 className="my-5">Projekty zakończone w 2023 roku:</h4>
        <div className="row">
          <div>
            <ProjectCard active={false} title="Spływy kajakowe z Wawra" description="Bezpłatne spływy kajakowe po Wiśle na trasie Plaża Romantyczna - Śródmieście. Spływy odbywają się z udziałem przewodnika i asystą ratowników na łodziach motorowych." bookingUrl="https://kajakwstolicy.pl/bezplatne-splywy-z-wawra-2023/" />
          </div>
          <div>
            <ProjectCard active={false} title="Łączą nas wiosła" description="Bezpłatne zajęcia w ramach akcji Łączą nas wiosła 2023. 2-godzinne zajęcia obejmują szkolenie z pływania na łodziach wioślarskich, deskach SUP oraz kajakach pod okiem profesjonalnych instruktorów." bookingUrl="https://wodnawarszawa.pl/aktywnie-nad-wisla-2023/" />
          </div>
          <div>
            <ProjectCard active={false} title="Rejsy Na Równonoc" description="Bezpłatne rejsy w ramach Nadwiślańskiej Równonocy (23.09)." bookingUrl="https://wodnawarszawa.pl/rejsy-rownonoc-2023/" />
          </div>
          <div>
            <ProjectCard active={false} title="Rejsy World CleanUp Day" description="Bezpłatne 1,5-godzinne rejsy w ramach akcji World CleanUp Day (16.09)." bookingUrl="https://wodnawarszawa.pl/rejsy-world-cleanup-day-2023/" />
          </div>
          <div className="col-sm-6">
            <ProjectCard active={false} title="Otwarcie Sezonu nad Wisłą" description="Bezpłatne atrakcje w ramach wydarzenia Otwarcie Sezonu Nad Wisłą 2023. Wśród atrakcji m.in. rejsy łodziami, warsztaty z pływania deskami SUP, zajęcia na łodziach wioślarskich czy żaglówce." bookingUrl="https://wodnawarszawa.pl/otwarcie-sezonu-2023/" />
          </div>
          <div className="col-sm-6">
            <ProjectCard active={false} title="Wypożyczalnia kajaków w Parku Skaryszewskim" description="Bezpłatna wypożyczalnia kajaków na Pradze-Południe. Do dyspozycji 12 kajaków 2-osobowych oraz możliwość wypożyczenia sprzętu do 1 godz." bookingUrl="https://kajakwstolicy.pl/wypozyczalnia-na-pradze-2023/" />
          </div>
          <div className="col-sm-6">
            <ProjectCard active={false} title="Spływy wakacyjne" description="Bezpłatne spływy po Wiśle na trasach Wawer - Śródmieście oraz Śródmieście - Zoo. Spływ jest samodzielny, przed wypłynięciem udzielany jest instruktaż." bookingUrl="https://kajakwstolicy.pl/splywy-wakacyjne/" />
          </div>
          <div className="col-sm-6">
            <ProjectCard active={false} title="Święto Wisły 2023" description="Bezpłatne atrakcje w ramach wydarzenia Święta Wisły 2 września 2023. Czeka na Was wiele atrakcji. Rejsy oraz wydarzenia towarzyszące." bookingUrl="https://wodnawarszawa.pl/swieto-wisly-2023/" />
          </div>
          <div className="col-sm-6">
            <ProjectCard active={false} title="Wypożyczalnia kajaków - Kanał Żerański" description="Bezpłatna wypożyczalnia kajaków na Kanale Żerańskim. Do dyspozycji 12 kajaków jedno lub dwuosobowych.Możliwość wypożyczenia sprzętu do 2 godz." bookingUrl="https://kajakwstolicy.pl/wypozyczalnia-na-zeraniu-2023/" />
          </div>
          <div className="col-sm-6">
            <ProjectCard active={false} title="Spływy do Nieporętu" description="Bezpłatne spływy na trasie Śródmieście - Nieporęt. Spływy zorganizowane z przewodnikiem i śluzowaniem." bookingUrl="https://kajakwstolicy.pl/bezplatne-splywy-do-nieporetu-2023/" />
          </div>
        </div>
      </PageSection>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns:{in: ["navigation","footbar", "main_page", "main_page_hero","main_page_intro", "main_page_bullets"]},language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;